@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'HelveticaNeue';
  src: url('./fonts/HelveticaNeue-Condensed.ttf');
  src: url('./fonts/HelveticaNeue-Condensed.eot?#iefix') format('embedded-opentype'),
  url('./fonts/HelveticaNeue-Condensed.woff') format('woff'),
  url('./fonts/HelveticaNeue-Condensed.woff2') format('woff2'),
  url('./fonts/HelveticaNeue-Condensed.ttf') format('truetype'),
  url('./fonts/HelveticaNeue-Condensed.svg#HelveticaNeue-Condensed') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
	font-family: 'HelveticaNeue';
	src: url('./fonts/HelveticaNeue-BoldCond.ttf');
	src: url('./fonts/HelveticaNeue-BoldCond.eot?#iefix') format('embedded-opentype'),
  url('./fonts/HelveticaNeue-BoldCond.woff') format('woff'),
  url('./fonts/HelveticaNeue-BoldCond.ttf') format('truetype'),
  url('./fonts/HelveticaNeue-BoldCond.svg#HelveticaNeue-BoldCond') format('svg');
	font-weight: 700;
	font-style: normal;
}

body {
  font-family: 'HelveticaNeue', 'Helvetica', sans-serif;
}

input[type="checkbox"]:checked + div {
  @apply border-primary bg-primary;
}

input[type="checkbox"]:checked + div svg {
  @apply block;
}

.device-table tr td {
  padding-bottom: 0.25em;
}

.container-1\/2 { width: 100%; }
@media (min-width: 1536px) {
  .container-1\/2 { max-width: 720px; }
}

@layer utilities {
  .filter-sun-yellow {
    filter: invert(83%) sepia(22%) saturate(6115%) hue-rotate(352deg) brightness(100%) contrast(97%);
  }
  
  .filter-midnight {
    filter: invert(6%) sepia(5%) saturate(5947%) hue-rotate(183deg) brightness(88%) contrast(90%);
  }
  
  .filter-silver-light {
    filter: invert(100%) sepia(1%) saturate(4275%) hue-rotate(27deg) brightness(81%) contrast(128%);
  }
}
